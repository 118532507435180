"use strict";

/**
 * Created by Leonardo B 07/07/2015.
 */
(function ($) {
  var ALREADY_RELATIVE_POSITION = ['relative', 'absolute', 'fixed'];
  var MESSAGE_TIMEOUT_15_IN_MS = 15000;
  var MESSAGE_TIMEOUT_30_IN_MS = 30000;
  var MESSAGE_TIMEOUT_45_IN_MS = 60000;
  var LOADER_BY_TYPE = {
    small: "\n\t\t<div class=\"sk-spinner stilingue-colors sk-spinner-three-bounce small-loader\">\n\t\t\t<div class=\"sk-bounce1\"></div>\n\t\t\t<div class=\"sk-bounce2\"></div>\n\t\t\t<div class=\"sk-bounce3\"></div>\n\t\t",
    big: "\n\t\t<div class=\"sk-spinner stilingue-colors sk-spinner-three-bounce big-loader\">\n\t\t\t<div class=\"sk-bounce1\"></div>\n\t\t\t<div class=\"sk-bounce2\"></div>\n\t\t\t<div class=\"sk-bounce3\"></div>\n\t\t\t<div class=\"sk-bounce4\"></div>\n\t\t\t<div class=\"sk-bounce5\"></div>\n\t\t\t<div class=\"sk-bounce6\"></div>\n\t\t\t<div class=\"sk-bounce7\"></div>\n\t\t</div>\n\t\t<span class=\"loader-text\"></span>\n\t\t",
    "default": "\n\t\t<div class=\"sk-spinner stilingue-colors sk-spinner-three-bounce medium-loader\">\n\t\t\t<div class=\"sk-bounce1\"></div>\n\t\t\t<div class=\"sk-bounce2\"></div>\n\t\t\t<div class=\"sk-bounce3\"></div>\n\t\t\t<div class=\"sk-bounce4\"></div>\n\t\t\t<div class=\"sk-bounce5\"></div>\n\t\t\t<div class=\"sk-bounce6\"></div>\n\t\t\t<div class=\"sk-bounce7\"></div>\n\t\t</div>\n\t\t"
  };
  function checkContainer(container) {
    if (!container) {
      return 'Could not create loader, container doesnt exist';
    }
    if (!(container.startsWith('.') || container.startsWith('#'))) {
      return 'Could not create loader, container must be class or id';
    }
    if (!document.querySelector(container)) {
      return "container doesnt exist: ".concat(container);
    }
  }
  window.StLoader = function StLoader(type, container) {
    this.type = type;
    this.status = 'stopped';
    this.container = container;
    this.timeOut15 = '';
    this.timeOut30 = '';
    this.timeOut45 = '';
    this.reachTimeOut = '';
    if (!this.container) {
      console.error('Could not create loader, container doesnt exist');
      return;
    }
    this.start = function () {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$clearContainer = _ref.clearContainer,
        clearContainer = _ref$clearContainer === void 0 ? false : _ref$clearContainer;
      var containerProblem = checkContainer(this.container);
      if (containerProblem) {
        console.error(containerProblem);
        return false;
      }
      var alreadyExist = this.status === 'started';
      if (alreadyExist) {
        this.stop();
        this.start();
        console.info('Restarting loader...');
        return false;
      }
      this.status = 'started';
      var $mainContainer = $(this.container).first();
      var loaderSuperDivs = '<div class="super_loader_container"> <div class="loader_position"></div></div>';
      var loader = LOADER_BY_TYPE[type] || LOADER_BY_TYPE["default"];

      // noinspection JSJQueryEfficiency
      $("".concat(this.container, " .super_loader_container")).remove();
      if (clearContainer) {
        $mainContainer.html('');
      }
      $mainContainer.prepend(loaderSuperDivs);
      var positionCss = $mainContainer.css('position');
      if (!ALREADY_RELATIVE_POSITION.includes(positionCss)) {
        $mainContainer.css('position', 'relative');
      }

      // noinspection JSJQueryEfficiency
      $("".concat(this.container, " .super_loader_container")).append(loader);
      this.startLoaderTextEvent();
      return true;
    };
    this.stop = function () {
      this.status = 'stopped';
      $("".concat(this.container, ">.super_loader_container")).remove();
      clearTimeout(this.timeOut15);
      clearTimeout(this.timeOut30);
      clearTimeout(this.timeOut45);
      clearTimeout(this.reachTimeOut);
      return true;
    };
    this.startLoaderTextEvent = function () {
      if (this.type !== 'big') return;
      var $textContainer = $("".concat(this.container, " .loader-text")).first();
      if ($textContainer.length) {
        $textContainer.html('<b>Processando...</b>');
        this.timeOut15 = setTimeout(function () {
          $textContainer.html('<b>Recebendo os dados...</b>');
        }, MESSAGE_TIMEOUT_15_IN_MS);
        this.timeOut30 = setTimeout(function () {
          $textContainer.html('<b>Agregando as informações...</b>');
        }, MESSAGE_TIMEOUT_30_IN_MS);
        this.timeOut45 = setTimeout(function () {
          $textContainer.html('<b>Aplicando toques finais...</b>');
        }, MESSAGE_TIMEOUT_45_IN_MS);
      } else {
        console.error('Didnt find text container');
      }
    };
  };
  window.StLoader.small = function (containerId) {
    return new window.StLoader('small', containerId);
  };
})(window.jQuery);